// 生产环境接口配置
export const ApiConfig = {
    mzl: {
        url: "https://ms.6mi6.com/api/prod/mzl/v2.0",
        key: "83yqA365mzl141901",
        secret: "239d2d9fb16d8218d81c54d1764bd33b"
    },
    mic: {
        url: "https://ms.6mi6.com/api/prod/mic/v2.0",
        key: "83yqA365mic0014197",
        secret: "6c2a11424e7cf00a7b806c7537b31657dbe340e26"
    },
    user: {
        url: "https://ms.6mi6.com/api/prod/pp/v2.0",
        key: "83yqA365pp00141902",
        secret: "f9058a6589ksb251fdbef49d357d88507b081c4f"
    },
    report: {
        url: "https://ms.6mi6.com/api/prod/rps/v2.0",
        key: "83yqA365rps0014196",
        secret: "f09c589dcda7058dcb1d11d58820e75a5dea2159"
    },
    prod: {
        url: "https://ms.6mi6.com/prod/api/openapi",
        key: "meizhuliagent9988",
        secret: "c4209b10f6f11215fed317db9c7563cefc48c72c"
    },
    sms: {
        url: "https://ms.6mi6.com/api/prod/sms/v2.0",
        key: "83yqA365rps0014196",
        secret: "f09c589dcda7058dcb1d11d58820e75a5dea2159"
    },
    member: {
        url: 'http://192.168.1.237:8091/mzl/upload',
    },
    admin: {
        url: "https://mzl-admin-api.sixsix.shop",
    },
    help: {
        // url: "http://192.168.1.200:3002/"
        url: "https://admin-v1.sixsix.shop/", //页面跳转地址
    },
    make: { //张全成新项目地址
        url: "https://mzl-e2.sixsix.shop"
    },
    pay: { //张全成新项目地址
        url: "https://ms.6mi6.com/pay/adapay/",   
        key : "meizhuliapi6688",
        secret :"211a530dd2adcb4eeb71e5d110863eb6237c1328"
    },
    wxx: { //微信小程序H5地址 
        url: "https://ms.6mi6.com/h5"
    },
    stage: { //新后台管理地址
        url: "https://wx-api.sixsix.shop"
    }, 
    wx: { //微信权限地址
        url: "https://ms.6mi6.com",
        key: "mzlwxapi",
        secret:"fs2309soa22430098fs"
    },
    shop: { //商城
        url: "https://ms.6mi6.com/api/prod/shop/v2.0",
        key: "83yqA365mzl141847",
        secret:"d41d8cd98f00b204e9800998ecf8427e"
    },

    isLog: false
};


let host = window.location.host;                                                                                  

// 是否为本地开发环境
function isLocal(host) {
    // return true; //强制改成测试环境，用于打包测试.
    return false ///强制改成正式环境，用于本/地测试
   if(host.startsWith("192.168.1."))return true;
   return host.startsWith("localhost") || host.startsWith("127.0.0.1");
}
// host = "mzl-app-nightly.sixsix.shop"
// 夜版环境配置, 访问网址: https://mzl-app-nightly.sixsix.shop
if (host.indexOf("nightly.") !== -1) {
    // if(false){
    // 测试环境
    ApiConfig.mzl = {
        url: "https://mzl-api-nightly.sixsix.shop/prod/mzl/v2.0",
        key: "83yqA365mzl141901",
        secret: "239d2d9fb16d8218d81c54d1764bd33b"
    };
    ApiConfig.mic = {
        url: "https://mzl-api-nightly.sixsix.shop/prod/mic/v2.0",
        key: "83yqA365mic0014197",
        secret: "6c2a11424e7cf00a7b806c7537b31657dbe340e26"
    };
    ApiConfig.user = {
        url: "https://mzl-api-nightly.sixsix.shop/prod/pp/v2.0",
        key: "83yqA365pp00141902",
        secret: "f9058a6589ksb251fdbef49d357d88507b081c4f"
    };
    ApiConfig.report = {
        url: "https://mzl-api-nightly.sixsix.shop/prod/rps/v2.0",
        key: "83yqA365rps0014196",
        secret: "f09c589dcda7058dcb1d11d58820e75a5dea2159"
    };
    ApiConfig.sms = {
        url: "https://mzl-api.dev.sixsix.shop/prod/sms/v2.0",
        key: "83yqA365rps0014196",
        secret: "f09c589dcda7058dcb1d11d58820e75a5dea2159"
    };
    ApiConfig.prod = {
        url: "https://ms.6mi6.com/prod/api/openapi",
        key: "meizhuliagent6688",
        secret: "c4200b10f6f11215fed317db9c7563cefc48c72c"
    };
    ApiConfig.pay = { //张全成新项目地址
        url: "https://ms.6mi6.com/pay/adapay/",
        key : "meizhuliapi6688",
        secret : "211a530dd2adcb4eeb71e5d110863eb6237c1328"
    };
    ApiConfig.isLog = true;
} else if (host.indexOf("dev.") !== -1) {
    // 测试环境
    ApiConfig.mzl = {
        // url: "http://192.168.1.129:7020/prod/mzl/v2.0",
        url: "http://mzl-api.dev.sixsix.shop/prod/mzl/v2.0",
        key: "83yqA365mzl141901",
        secret: "239d2d9fb16d8218d81c54d1764bd33b"
    };
    ApiConfig.mic = {
        url: "http://mzl-api.dev.sixsix.shop/prod/mic/v2.0",
        key: "83yqA365mic0014197",
        secret: "6c2a11424e7cf00a7b806c7537b31657dbe340e26"
    };
    ApiConfig.user = {
        url: "http://mzl-api.dev.sixsix.shop/prod/pp/v2.0",
        key: "83yqA365pp00141902",
        secret: "f9058a6589ksb251fdbef49d357d88507b081c4f"
    };
    ApiConfig.report = {
        url: "http://mzl-api.dev.sixsix.shop/prod/rps/v2.0",
        // url: "http://192.168.1.9:7020/prod/rps/v2.0",
        key: "83yqA365rps0014196",
        secret: "f09c589dcda7058dcb1d11d58820e75a5dea2159"
    };
    ApiConfig.sms = {
        url: "http://mzl-api.dev.sixsix.shop/prod/sms/v2.0",
        key: "83yqA365rps0014196",
        secret: "f09c589dcda7058dcb1d11d58820e75a5dea2159"
    };
    ApiConfig.prod = {
        // url: "http://prod-service.dev.sixsix.shop:8089/openapi",
        url: "http://192.168.1.14:8091/openapi",
        key: "meizhuliagent6688",
        secret: "c4200b10f6f11215fed317db9c7563cefc48c72c"
    };
    ApiConfig.isLog = true;
} else if (isLocal(host)) {
    //开发环境
    ApiConfig.mzl = {
        url: "http://mzl-api.dev.sixsix.shop/prod/mzl/v2.0",
        // url: "http://192.168.1.129:7020/prod/mzl/v2.0",
        key: "83yqA365mzl141901",
        secret: "239d2d9fb16d8218d81c54d1764bd33b"
    };
    ApiConfig.mic = {
        url: "http://mzl-api.dev.sixsix.shop/prod/mic/v2.0",
        key: "83yqA365mic0014197",
        secret: "6c2a11424e7cf00a7b806c7537b31657dbe340e26"
    };
    ApiConfig.user = {
        url: "http://mzl-api.dev.sixsix.shop/prod/pp/v2.0",
        key: "83yqA365pp00141902",
        secret: "f9058a6589ksb251fdbef49d357d88507b081c4f"
    };
    ApiConfig.report = {
        url: "http://mzl-api.dev.sixsix.shop/prod/rps/v2.0",
        // url:"http://192.168.1.9:7020/prod/rps/v2.0",
        key: "83yqA365rps0014196",
        secret: "f09c589dcda7058dcb1d11d58820e75a5dea2159"
    };
    ApiConfig.sms = {
        url: "http://mzl-api.dev.sixsix.shop/prod/sms/v2.0",
        key: "83yqA365rps0014196",
        secret: "f09c589dcda7058dcb1d11d58820e75a5dea2159"
    };
    ApiConfig.prod = {
        // url: "http://prod-service.dev.sixsix.shop:8089/openapi",
        url: "http://192.168.1.14:8091/openapi",
        key: "meizhuliagent6688",
        secret: "c4200b10f6f11215fed317db9c7563cefc48c72c"
    };
    ApiConfig.admin = {
        url: "http://192.168.1.200:3002",
    };
    ApiConfig.help = {
        // url: "http://192.168.43.122:3001/",
        url: "http://192.168.1.200:3002/",
    };
    ApiConfig.make = { //张全成新项目地址
        url: "http://192.168.1.211:8082"
    };
    ApiConfig.pay = { //张全成新项目地址
        url: "http://192.168.1.38:8082/pay/adapay/",
        key : "meizhuliapi6688",
        secret : "211a530dd2adcb4eeb71e5d110863eb6237c1328"
    };
    ApiConfig.wxx = {  //微信小程序H5地址   
        url: "http://192.168.1.219:3001"
    };
    ApiConfig.stage = { //新后台管理地址
        url: "http://192.168.1.14:8080/"
    }
    ApiConfig.shop = { //商城
        url: "http://mzl-api.dev.sixsix.shop/prod/shop/v2.0",
        key: "83yqA365mzl141847",
        secret:"d41d8cd98f00b204e9800998ecf8427e"
    }

    ApiConfig.isLog = false;
}

export default ApiConfig;



export const isLog = ApiConfig.isLog; //是否开启日志
export var noCheck = "NoCheck"; //不检查登录状态
export const pageSize = 7; //页面表格item条数
export const money_max = 999999; //金额最大值 
export const mzlVersion = "2.17.9"; //版本号
export const mzlPhone = "13162070787"; //66掌柜客服电话
export const mzlCopyright = "2024"; //66掌柜版权
export const qqMapKey = "OMSBZ-WDL62-FXQUO-C565X-I5FC2-ITFI7"; //腾讯地图key
//默认头像
// export const appHeadImg = 'https://line365-img-source.oss-cn-beijing.aliyuncs.com/mzl-img/wxbf66cc2718ea552b.o6zAJsyBTCmqYcd96MRWjVoaputw.Ik8dllewVLaec001d51ea257f87c3e9b85143d1ebbcc.jpg';
export const appHeadImg = 'https://ms.6mi6.com/app/logo.svg';
// export const appHeadImg = 'https://line3 65-img-source.oss-cn-beijing.aliyuncs.com/mzl-img/wxbf66cc2718ea552b.o6zAJsyBTCmqYcd96MRWjVoaputw.Ik8dllewVLaec001d51ea257f87c3e9b85143d1ebbcc.jpg';
/**
 * 购买模块的ID
 * @type {number}
 */
export const ModuleTag = {
    basics: 1,//基础模块
    employeeBonus: 16,//员工分红
    cashCoupon: 18,//现金券
    luckDraw: 19,//抽奖
    union: 20,//股东分红
};
/**
 * 权限模块的ID
 * @type {number}
 */
export const ModuleId = {
    basics: 47,//基础模块
    stock: 48,//库存
    cardMarketing: 49,//开卡充值营销
    cashCoupon: 50,//现金券
    luckDraw: 51,//抽奖
    smartStay: 52,//智能留客
    retailMarketing: 53,//散客营销
    closedLoopTuoke: 54,//闭环拓客
    appointment: 55,//预约
    integral: 56,//积分
    checkWork: 57,//考勤
    sdi: 60,//轮牌手牌
    store1: 67,//商城初级版
    store2: 68,//商城中级版
    store3: 69,//商城高级版
};


//  功能菜单编号
export const FnMenuId = {
    head_home: "10001",//首页
    head_collect: "10002", //收银
    head_collect_zcdd: "10002-1", //暂存的订单
    head_collect_kk: "10002-2", //开卡
    head_collect_cz: "10002-3", //充值
    head_collect_zc: "10002-4", //暂存
    head_collect_js: "10002-5", //结算
    head_order: "10003",  //订单
    head_order_orderList: '10003-1',//订单列表
    head_order_arrears: '10003-2',//欠款
    head_order_waterListCheck: '10003-3',//水单核对
    head_vip: "10004",//会员
    head_vip_hymd: "10004-1",//会员-会员名单
    head_vip_kkyx: "10004-3",//会员-开卡营销
    head_vip_czyx: "10004-4",//会员-充值营销
    head_vip_dxyx: "10004-5",//会员-短信营销
    head_vip_hyfx: "10004-6",//会员-会员分析
    head_marketing: "10005",//营销
    head_marketing_dxyx: "100005-9",//营销-短信营销
    head_marketing_ygfh: "100005-4",//营销-员工分红
    head_marketing_sgyx: "100005-5",//营销-散客营销
    head_marketing_gdfh: "100005-10",//营销-股东分红
    head_marketing_xjq: "100005-6",//营销-现金券
    head_marketing_cj: "100005-7",//营销-抽奖
    head_marketing_lmfh: "100005-8",//营销-闭环拓客
    head_finance: "10006",//财务
    head_stock: "10007",//库存
    head_stock_jhgl: "10007-1",//库存-进货管理
    head_stock_jxbb: "10007-2",//库存-进销报表
    head_stock_ypgl: "10007-3",//库存-院品管理
    head_stock_kcpd: "10007-4",//库存-库存盘点
    head_stock_gqtx: "10007-5",//库存-过期提醒
    head_stock_gcyj: "10007-6",//库存-库存预警
    head_stock_gysgl: "10007-7",//库存-供应商管理
    head_stock_rktj: "10007-11",//库存-入库统计
    head_stock_cktj: "10007-12",//库存-出库统计
    head_stock_kcyj: "10007-13",//库存-库存预警

    head_stock_new_spgl: "10107-1",//n库存-新-商品管理
    head_stock_new_rugl: "10107-2",//n库存-新-入库管理
    head_stock_new_ckgl: "10107-3",//n库存-新-出库管理
    head_stock_new_sqdb: "10107-4",//n库存-新-申请调拨
    head_stock_new_kctj: "10107-5",//n库存-新-库存统计
    head_stock_new_rutj: "10107-6",//n库存-新-入库统计
    head_stock_new_cktj: "10107-7",//n库存-新-出库统计
    head_stock_new_kcyj: "10107-8",//n库存-新-库存预警
    head_report: '10008',//报表
    head_report_yysj2: '10008-8',//报表-营业数据2
    head_report_ygyj: '10008-9',//报表-员工业绩
    head_report_yysj: '10008-7',//报表-会员报表
    head_report_yyfx: '10008-2',//报表-营业分析
    head_report_kdxf: '10008-10',//报表-跨店消费

    head_menu: '10009',//菜单
    head_icon: '10010',//切换con
    head_setting: "10011",//设置
    head_setting_dpsz: "10011-1",//设置-店铺设置
    head_setting_fjsz: "10011-7",//设置-房间设置
    head_setting_lbsz: "10011-14",//设置-轮班设置
    head_setting_yysz: "10011-10",//设置-预约设置
    head_setting_jfsz: "10011-11",//设置-积分设置
    head_setting_kqsz: "10011-15",//设置-考勤设置
    head_setting_smf: "10011-16",//设置-扫码付
    head_setting_rjsq: "10011-8",//设置-软件授权
    head_setting_zdbf: "10011-0",//设置-软件授权
    head_setting_tysz: "10011-17",//设置-通用设置
    head_setting_kdxf: "10011-18",//设置-跨店消费
    head_userCenter: '10000-3',//用户中心
    head_userCenter_zhsz: '10000-3-1',//账号设置
    head_userCenter_wddd: '10000-3-2',//我的订单
    head_userCenter_yjxf: '10000-3-3',//软件续费
    head_licence: '10000',//授权
    head_reset: "10000-2",//切换账号
    head_rjsq: "10000-4",//软件授权
    head_qhsf: "10000-5",//切换身份
    head_vip_1: "10004-1",
    head_vip_2: "10004-2",
    head_agree: '10012',//预约
    head_printer: '10123-9',//打印设置

    head_store: '10014',//商城

    user_settting :"100015",
    user_settting_rjjh :"100015-1",
    user_settting_yhzx :"100015-2",




    //连锁店的chain 设置
    head_chain_setting: '20000',//设置
    head_chain_setting_index: '20000-1',//连锁设置
    head_chain_setting_shopSetting: '20000-2',//连锁店的店铺设置
    head_chain_setting_consume: '20000-3',//跨店消费
    head_chain_setting_myOrder: '20000-4',//跨店消费
    head_chain_report: '20001',//报表
    head_chain_report_overView: '20001-1',//总览
    head_chain_report_businessData: '20001-2',//营业数据
    head_chain_report_performanceRanking: '20001-3',//业绩排名
    head_chain_report_membershipReport: '20001-4',//会员报表
    head_chain_report_goods: '20002',//库存商品



    //连锁店的chain 后台
    head_chain_manage: '30000',//连锁后台



    //账号设置
    head_chain_account: '30001',//连锁后台
    head_chain_account_index: '30001-2',


    //连锁设置首页
    head_chain_setting_index1: '40000',//连锁后台


};









